import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql, StaticQuery } from 'gatsby';
import { styled } from '@mui/system';
import theme from '../theme';

import PreviewCompatibleImage from './PreviewCompatibleImage';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';

import BnWSection from './BnWSection';
import BnWContent from './BnWContent';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
	top: 64,
});

const TeamCard = styled(Card)(({ theme }) => ({
	display: 'flex',
	// flexDirection: 'column',
	// justifyContent: 'center',
	position: 'relative',
	// color: theme.palette.primary.contrastText,
	// margin: theme.spacing(4),
	backgroundColor: 'initial',
	padding: theme.spacing(0),
	zIndex: 0,
	':after': {
		position: 'absolute',
		display: 'block',
		width: 'calc(100% - 16px)',
		height: 'calc(100% - 16px)',
		content: '" "',
		top: '8px',
		left: '8px',
		// border: '1px solid rgba(0, 0, 0, 0.15)',
		border: '4px solid rgba(255, 255, 255, 0.45)',
		// zIndex: -1,
		borderRadius: '5px',
	},
	'.MuiCardHeader-root': {
		// height: '220px',
		// color: theme.palette.primary.contrastText,
	},
	'.MuiCardContent-root': {
		// padding: theme.spacing(0, 4, 2),
		// paddingBottom: theme.spacing(2),
	},
	'.underColor': {
		backgroundColor: '#ffffff75',
	},
	'.filter': {
		position: 'absolute',
		backgroundColor: 'rgba(0,0,0, 0.1)',
		top: 0,
		left: 0,
		width: '100%',
		height: '100%',
		textAlign: 'center;',
		zIndex: '0',
		transition: 'all 0.5s ease',
	},
	'&:hover': {
		transition: 'all 0.5s ease',
		color: theme.palette.primary.main,
		'& .filter': {
			backgroundColor: 'rgba(255, 255, 255, 0)',
			transition: 'all 0.5s ease',
			transform: 'scale(1.2)',
		},
		'& .MuiButton-root': {
			opacity: 1,
		},
		'& .MuiCardHeader-root': {
			top: 0,
		},
		// '& .filter': {
		// 	transition: 'all 0.5s ease',
		// 	zIndex: -1,
		// },
	},
}));

const TeamSideTemplate = (props) => {
	const { data } = props;
	const { edges: posts } = data.allMarkdownRemark;

	return (
<>
				{posts &&
					posts.map(({ node: post }) => (
							<TeamCard
								key={post.id}
								sx={{
									flexDirection: 'column',
									width: '18%',
									margin: '10px'
								}}
							>
								{post.frontmatter.featuredimage ? (
									<Box
									// sx={{
									// 	position: 'relative',
									// 	top: '-8px',
									// 	width: 'calc(100% + 16px)',
									// 	left: '-8px',
									// }}
									>
										<PreviewCompatibleImage
											position='relative'
											// widthOuter='200px'
											// widthInner='200px'
											imageInfo={{
												image: post.frontmatter.featuredimage,
												alt: `featured image thumbnail for post ${post.frontmatter.title}`,
											}}
										/>
									</Box>
								) : null}
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'column',
										zIndex: 1,
										position: 'absolute',
										bottom: '16px',
										width: '100%',
									}}
								>
									<CardHeader
										className='header'
										sx={{
											p: 0,
											position: 'relative',
											top: '48px',
											transition: 'top .5s ease',
										}}
										title={
											<Typography variant='h6' align='center'>
												<span className='underColor'>{post.frontmatter.title}</span>
											</Typography>
										}
										// subheader={
										// 	<Typography
										// 		variant='body2'
										// 		sx={{
										// 			p: 0,
										// 			ul: {
										// 				// fontSize: '1rem',
										// 				listStyleType: 'none',
										// 				pl: '2px',
										// 				lineHeight: '1.2',
										// 				li: { pb: 1 },
										// 			},
										// 		}}
										// 		dangerouslySetInnerHTML={{
										// 			__html: post.frontmatter.description.html,
										// 		}}
										// 	/>
										// }
										titleTypographyProps={{ color: theme.palette.main }}
									/>
									<CardContent
										sx={{
											p: 0,
											ul: {
												fontSize: '1rem',
												listStyleType: 'none',
												p: 0,
												lineHeight: '1.2',
												li: { pb: 1 },
											},
										}}
									>
										{/* <Typography variant='h3'>{post.frontmatter.title}</Typography> */}
										{/* <Typography variant='body1' className='description'>
											<span className='underColor'>{post.excerpt}</span>
										</Typography> */}
										{/* <Box
											dangerouslySetInnerHTML={{
												__html: post.frontmatter.description.html,
											}}
										/> */}
									</CardContent>
									<CardActions
										className='footer text-center'
										sx={{
											display: 'flex',
											justifyContent: 'center',
											zIndex: 1,
										}}
									>
										<Button
											variant='contained'
											component={Link}
											to={post.fields.slug}
											size='large'
											sx={{
												fontSize: '0.8rem',
												opacity: 0,
												transition: 'opacity .35s ease',
											}}
										>
											More
										</Button>
									</CardActions>
								</Box>
								<Box className='filter'></Box>
							</TeamCard>
					))}
					</>
	);
};

BlogRoll.propTypes = {
	data: PropTypes.shape({
		allMarkdownRemark: PropTypes.shape({
			edges: PropTypes.array,
		}),
	}),
};

export default function BlogRoll() {
	return (
		<StaticQuery
			query={graphql`
				query TeamSideQuery {
					allMarkdownRemark(
						sort: { fields: [frontmatter___order], order: ASC }
						filter: { frontmatter: { templateKey: { eq: "team-member" } } }
					) {
						edges {
							node {
								excerpt(pruneLength: 400)
								id
								fields {
									slug
								}
								frontmatter {
									title
									templateKey
									order
									description {
										html
									}
									featuredimage {
										childImageSharp {
											gatsbyImageData(quality: 100, layout: FULL_WIDTH)
										}
									}
								}
							}
						}
					}
				}
			`}
			render={(data, count) => <TeamSideTemplate data={data} count={count} />}
		/>
	);
}
