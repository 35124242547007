import React from 'react';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';
import { Helmet } from 'react-helmet';
import { graphql, Link } from 'gatsby';
import Layout from '../components/Layout';
import Content, { HTMLContent } from '../components/Content';
import { styled } from '@mui/system';
import Box from '@mui/system/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import FaceIcon from '@mui/icons-material/Face';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';

import BnWSection from '../components/BnWSection';
import BnWContent from '../components/BnWContent';
import TeamSideBar from '../components/TeamSideBar';
import SectionNavComponent from '../components/Navbar-v3';

import { Container, Typography } from '@mui/material';

const Section = styled(Box)({
	display: 'flex',
	justifyContent: 'center',
	// backgroundColor: '#ffffff',
	zIndex: 1,
});

// eslint-disable-next-line
export const TeamMemberTemplate = ({
	content,
	contentComponent,
	description,
	tags,
	title,
	helmet,
	featuredimage,
}) => {
	const PostContent = contentComponent || Content;

	return (
		<>
			<Container sx={{ display: 'flex' }}>
				<BnWSection
					className='section'
					sx={{  flexWrap: 'wrap', justifyContent: 'space-evenly', backgroundColor: 'transparent' }}
					// ml={8}
					// mt={6}
					borderRadius={2}
					elevation={3}
				>
					{helmet || ''}
					<BnWContent mb={6} px={2} borderRadius={2} elevation={3} sx={{boxShadow: 12, backgroundColor: '#fff'}}>
						<Grid
							container
							spacing={4}
							sx={{ justifyContent: 'space-between' }}
							py={4}
						>
							<Grid item xs={3} pr={3}>
								<Box
									sx={{
										position: 'relative',
										top: '-32px',
										width: 'calc(100% + 32px)',
										left: '-16px',
										borderTopLeftRadius: '8px',
										overflow: 'hidden',
									}}
								>
									<PreviewCompatibleImage
										position='relative'
										widthOuter='100%'
										imageInfo={{
											image: featuredimage,
											alt: `featured image thumbnail for post ${title}`,
										}}
									/>
								</Box>
								<Box
									sx={{
										p: 0,
										ul: {
											listStyleType: 'none',
											textAlign: 'center',
											pl: '2px',
											lineHeight: '1.2',
											li: { pb: 1, mb: 1, borderBottom: '1px solid #e3e3e3' },
										},
									}}
									dangerouslySetInnerHTML={{ __html: `${description}` }}
								/>
								{/* {tags && tags.length ? (
									<>
										<Typography variant='h4' align='center'>
											Specialities
										</Typography>
										<Box className='taglist'>
											{tags.map((tag) => (
												<Chip
													key={tag + `tag`}
													icon={<FaceIcon />}
													label={tag}
													variant='outlined'
													component='a'
													href={`/tags/${kebabCase(tag)}/`}
													clickable
													sx={{ borderRadius: 2, mx: 0.2, my: 0.5 }}
												/>
											))}
										</Box>
									</>
								) : null} */}
							</Grid>
							<Grid item xs={9} p={8} className='section'>
								<Typography variant='h1' sx={{ fontSize: '2.3rem' }}>
									{title}
								</Typography>

								<PostContent content={content} />
							</Grid>
						</Grid>
					</BnWContent>
					{/* <Box sx={{ width: '30%' }} mr={8} mt={6}> */}
					<TeamSideBar />
					{/* </Box> */}
				</BnWSection>
			</Container>
		</>
	);
};

TeamMemberTemplate.propTypes = {
	content: PropTypes.node.isRequired,
	contentComponent: PropTypes.func,
	description: PropTypes.string,
	title: PropTypes.string,
	helmet: PropTypes.object,
};

const TeamMember = ({ data }) => {
	const { markdownRemark: post } = data;

	return (
		<Layout>
			{/* <BlogHeader className='full-width-image-container margin-top-0'>
				<Typography variant='h1'>The Team</Typography>
			</BlogHeader> */}
			<SectionNavComponent sx={{ mb: 0 }} />
			<TeamMemberTemplate
				content={post.html}
				contentComponent={HTMLContent}
				description={post.frontmatter.description.html}
				helmet={
					<Helmet titleTemplate='%s | Blog'>
						<title>{`${post.frontmatter.title}`}</title>
						<meta
							name='description'
							content={`${post.frontmatter.description.html}`}
						/>
					</Helmet>
				}
				tags={post.frontmatter.tags}
				title={post.frontmatter.title}
				featuredimage={post.frontmatter.featuredimage}
			/>
		</Layout>
	);
};

TeamMember.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.object,
	}),
};

export default TeamMember;

export const pageQuery = graphql`
	query TeamMemberByID($id: String!) {
		markdownRemark(id: { eq: $id }) {
			id
			html
			frontmatter {
				date(formatString: "MMMM DD, YYYY")
				title
				description {
					html
				}
				featuredimage {
					childImageSharp {
						gatsbyImageData(quality: 100, layout: FULL_WIDTH)
					}
				}
				tags
			}
		}
	}
`;
